<template>
  <div>
    <!-- select 2 demo -->

    <b-modal
      id="modal-login"
      no-close-on-backdrop
      centered
      ok-only
      size="md"
      hide-footer="true"
      title="Add Ocassion"
    >
      <b-form>
        <b-form-group>
          <b-row class="mt-1">
            <b-col cols="4">
              <h5 style="margin-top:10px;">Title</h5>
            </b-col>
            <b-col>
              <b-form-input
                v-model="myObj.title"
                placeholder="Enter occasion title"
                @focusout="checkTitle()"
                ref="title"
              />
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <b-row class="mt-2">
            <b-col cols="4">
              <h5 style="margin-top:10px;">Image</h5>
            </b-col>
            <b-col>
              <b-media no-body>
                <div v-if="logoloading == true">
                  <b-spinner
                    variant="primary"
                    type="grow"
                    class="m-5"
                    label="Spinning"
                  ></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      src="https://dk0pm9zdlq16s.cloudfront.net/8cbeb020-efb6-4a78-993c-871a94ddbbac.png"
                      @click="$refs.fileProfile.click()"
                      v-if="logoloading == false"
                      thumbnail
                      fluid
                      height="120px"
                      width="120px"
                    />

                    <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
                  </b-link>

                  <b-link v-if="logoloading == 'loaded'">
                    <div>
                      <i
                        @click="deleteLogo()"
                        class="fa fa-trash fa-sm text-danger position-absolute m-5"
                      ></i>
                    </div>
                    <b-img rounded :src="plus" height="120px" width="120px" />
                    <br />
                  </b-link>

                  <input
                    type="file"
                    id="fileProfile"
                    hidden
                    ref="fileProfile"
                    @change="selectlogo()"
                    accept="image/*"
                  />
                </b-media-aside>
              </b-media>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <br />
      <div style="">
        <b-button
          class="float-right ml-5"
          variant="primary"
          @click="AddFlavour()"
        >
          <span class="text-nowrap">Save</span>
        </b-button>
      </div>
    </b-modal>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Add Flavour -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-start">
              <div style="padding-right:15px">
                <b-button @click="OpenAddModal()" variant="primary">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="text-nowrap">Add Occasion</span>
                </b-button>
              </div>
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->
            </div>
          </b-col>
          <!-- Per Page -->
          <!-- <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col> -->
        </b-row>
      </div>

      <div>
        <b-table :items="items" :fields="fields" striped>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(image)="data">
            <b-img
              :src="data.value"
              style="width: 100px; height: 75px"
              rounded
              alt="Rounded image"
            />
          </template>

          <template #cell(actions)="data">
            <template>
              <feather-icon
                @click="OpenEditModal(data.item)"
                icon="EditIcon"
                size="16"
                class="align-middle text-body"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.placement.left
                title="Edit occasion"
              />
              <feather-icon
                @click="DeleteFlavour(data.item.occasionId)"
                icon="DeleteIcon"
                size="16"
                class="ml-2 align-middle text-body"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.placement.right
                title="Delete occasion"
              />
            </template>
            <!-- 
      <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item    @click="OpenEditModal(data.item)">
              <feather-icon icon="EditIcon" />
              
              <span class="align-middle ml-50"   >Edit</span>
            </b-dropdown-item>
              <b-dropdown-item    @click="DeleteFlavour(data.item.subCategoryId)">
              <feather-icon icon="XIcon" />
              
              <span class="align-middle ml-50"   >Delete</span>
            </b-dropdown-item>
              -->
            <!-- <b-dropdown-item @click="AcceptOrder(data.item.orderId)">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50" >Accept order</span>
            </b-dropdown-item>

            <b-dropdown-item @click="DenyOrder(data.item.orderId)">
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Decline order</span>
            </b-dropdown-item> 
          </b-dropdown>-->
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalOrders"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BSpinner,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBTooltip,
  BImg,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter';
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from 'vue-flatpickr-component';
import { BFormSelect } from 'bootstrap-vue';
//import Multiselect from "vue-multiselect";
import VueGallery from 'vue-gallery';

export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    //BMedia,
    //BAvatar,
    //BLink,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,

    //flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  created() {
    this.LoadData();
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      plus:
        'https://appick.io/u/lessons/64a03015-aaab-44a2-85c7-fc83ad9417b7.png',
      fileProfile: '',
      logoloading: false,
      errors: {
        status: false,
      },
      statusOptions: ['Active', 'Inactive'],
      filterData: '',
      date: {
        range: '',
      },
      index: null,
      cover: '',
      docs: [],
      partners: [],
      fields: [
        '#',
        { label: ' ', key: 'image' },
        { label: 'Title', key: 'title' },
        'actions',
      ],
      items: [],
      subCatItems: [],
      selected: null,
      filterOptions: [
        { text: 'Most sold product', value: 'mostSold' },
        { text: 'Least sold product', value: 'leastSold' },
        { text: 'Most popular product', value: 'mostPopular' },
        { text: 'Least popular product', value: 'leastPopular' },
        { text: 'Most profitable product', value: 'mostProfitable' },
      ],
      rangeDate: null,
      myObj: {
        occasionId: 0,
        title: '',
        image: '',
      },
      detailObj: {},
    };
  },
  methods: {
    checkTitle() {
      var elem = this.$refs['title'];
      return (elem.state = this.myObj.title.length > 2 ? true : false);
    },

    OpenAddModal() {
      this.$bvModal.show('modal-login');
      this.myObj.occasionId = 0;
      this.myObj.image = 0;
      this.myObj.title = '';
      this.logoloading = false;
      this.plus =
        'https://dk0pm9zdlq16s.cloudfront.net/8cbeb020-efb6-4a78-993c-871a94ddbbac.png';
    },
    OpenEditModal(row) {
      this.$bvModal.show('modal-login');
      this.myObj.occasionId = row.occasionId;
      this.myObj.image = row.image;
      this.myObj.title = row.title;
      this.plus = row.image;
      this.logoloading = 'loaded';
      console.log(this.plus);
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'delete',
            url: 'http://localhost:52736/api/occasions/Delete/' + id,
            headers: {
              Authorization:
                'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
            },
          };
          console.log(config.url);
          axios(config)
            .then(response => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire(
                  'Success!',
                  'Occasion has been deleted.',
                  'success',
                ).then(res => {
                  this.LoadData();
                });
              } else if (response.data.status === 'error') {
                Swal.fire('Not Allowed!', response.data.data, 'warning').then(
                  res => {
                    this.LoadData();
                  },
                );
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];
      var axios = require('axios');
      //console.log(this.fileProfile.name);

      //console.log(this.fileProfile, this.loading );
      //Upload cover
      if (this.fileProfile !== '') {
        this.logoloading = true;
        let formData = new FormData();
        formData.append('file', this.fileProfile);
        console.log(this.fileProfile);
        axios
          .post('https://upload.appick.io', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          .then(e => {
            console.log(e.data);
            // this.file = undefined;
            var fn1 = e.data.myresp[0].path;
            console.log(fn1);
            // console.log(fn);
            this.plus = fn1;
            this.myObj.image = this.plus;
            //
            console.log(this.plus);
            console.log('myImage', this.myObj.image);
            this.logoloading = 'loaded';

            console.log(this.loading);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.image = '';
      this.logoloading = false;
    },
    AddFlavour() {
      if (this.checkTitle() == false || this.myObj.image == '') {
        return this.$bvToast.toast('Please fill the form correctly.', {
          title: 'Error!',
          variant: 'danger',
          toaster: 'b-toaster-bottom-center',
        });
      } else {
        console.log(this.myObj);
        if (this.myObj.occasionId == 0) {
          console.log('Osama');
          var axios = require('axios');

          var config = {
            method: 'post',
            url: 'https://okaaik.fastech.pk/api/occasions/Post',
            headers: {
              Authorization:
                'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
            },
            data: this.myObj,
          };

          axios(config)
            .then(response => {
              if (response.data.status === 'success') {
                console.log(response.data);
                this.$bvModal.hide('modal-login');
                this.LoadData();

                this.$bvToast.toast('Occasion added.', {
                  title: 'Success!',
                  variant: 'success',
                  toaster: 'b-toaster-bottom-center',
                });
              }

              //  this.data  =  response.data;
              //console.log(this.$store.state.userData.userID);
              //this.items = response.data;

              // console.log(this.items);
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          //Edit
          var axios = require('axios');
          // this.$store.state.userData.userID
          var config = {
            method: 'put',
            url:
              'https://okaaik.fastech.pk/api/occasions/Put/' +
              this.myObj.occasionId,
            headers: {
              Authorization:
                'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
            },
            data: this.myObj,
          };

          axios(config)
            .then(response => {
              if (response.data.status === 'success') {
                this.$bvModal.hide('modal-login');
                console.log(response.data);
                this.LoadData();

                this.$bvToast.toast('Occasion updated.', {
                  title: 'Success!',
                  variant: 'success',
                  toaster: 'b-toaster-bottom-center',
                });
              } else {
                this.$bvToast.toast('Something went wrong.', {
                  title: 'Error!',
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-center',
                });
              }

              //  this.data  =  response.data;
              //console.log(this.$store.state.userData.userID);
              //this.items = response.data;

              // console.log(this.items);
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      }
    },

    LoadData() {
      var axios = require('axios');
      // this.$store.state.userData.userID
      var config = {
        method: 'get',
        url: 'https://okaaik.fastech.pk/api/occasions/GetAll/',
        headers: {
          Authorization:
            'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBZG1pbiIsImVtYWlsIjoiYWRtaW5AYnJhbmRhd2F5LmNvbSIsImp0aSI6IjVhYTNmZjY1LTg3MzMtNGZiZi05OWRhLWYxNTZlZmMyMjY0NSIsImV4cCI6MTY3MjA0NzgzNCwiaXNzIjoiYXBwaWNrc29sdXRpb25zLmlvLmNvbSIsImF1ZCI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20ifQ.5e8OU5ISM0HmK1urL9Tv3kWZ96nnxcXRfUMC7vBFZP8',
        },
      };

      axios(config)
        .then(response => {
          // console.log(JSON.stringify(response.data));

          //  this.data  =  response.data;
          console.log(response.data);
          this.items = response.data;

          console.log(this.items);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
